export default [
	{ id: '1', imageName: '1-lg.jpeg', tag: 'Particuliers' },
	{ id: '2', imageName: '2-lg.jpeg', tag: 'Professionnels' },
	{ id: '3', imageName: '3-lg.jpeg', tag: 'Materiels' },
	{ id: '4', imageName: '4-lg.jpeg', tag: 'Materiels' },
	{ id: '5', imageName: '5-lg.jpeg', tag: 'Particuliers' },
	{ id: '6', imageName: '6-lg.jpeg', tag: 'Professionnels' },
	{ id: '7', imageName: '7-lg.jpeg', tag: 'Materiels' },
	{ id: '8', imageName: '8-lg.jpeg', tag: 'Particuliers' },
	{ id: '9', imageName: '9-lg.jpeg', tag: 'Professionnels' },
	{ id: '10', imageName: '10-lg.jpeg', tag: 'Professionnels' },
	{ id: '11', imageName: '11-lg.jpeg', tag: 'Particuliers' },
	{ id: '12', imageName: '12-lg.jpeg', tag: 'Particuliers' },
	{ id: '13', imageName: '13-lg.jpeg', tag: 'Particuliers' },
	{ id: '14', imageName: '14-lg.jpeg', tag: 'Materiels' },
	{ id: '15', imageName: '15-lg.jpeg', tag: 'Particuliers' },
	{ id: '16', imageName: '16-lg.jpeg', tag: 'Professionnels' },
	{ id: '17', imageName: '17-lg.jpeg', tag: 'Particulers' },
	{ id: '18', imageName: '18-lg.jpeg', tag: 'Professionnels' },
	{ id: '19', imageName: '19-lg.jpeg', tag: 'Professionnels' },
	{ id: '20', imageName: '20-lg.jpeg', tag: 'Professionnels' },
	{ id: '21', imageName: '21-lg.jpeg', tag: 'Particulers' },
	{ id: '22', imageName: '22-lg.jpeg', tag: 'Professionnels' },
	{ id: '23', imageName: '23-lg.jpeg', tag: 'Particulers' },
	{ id: '24', imageName: '24-lg.jpeg', tag: 'Professionnels' },
	{ id: '25', imageName: '25-lg.jpeg', tag: 'Particulers' },
	{ id: '26', imageName: '26-lg.jpeg', tag: 'Professionnels' },
	{ id: '27', imageName: '27-lg.jpeg', tag: 'Particulers' },
	{ id: '28', imageName: '28-lg.jpeg', tag: 'Professionnels' },
	{ id: '29', imageName: '29-lg.jpeg', tag: 'Particulers' },
	{ id: '30', imageName: '30-lg.jpeg', tag: 'Professionnels' }
];