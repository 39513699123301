export const folio = [
    {
        pic: 'https://res.cloudinary.com/nascvi/image/upload/c_thumb,w_200,g_face/v1588691793/WhatsApp_Image_2020-05-04_at_14.41.50_pnkdee.jpg',
        desc: 'St-maur photo miniature',
        month: 'Bien',
        location: 'Maison Individuelle'
    },
    {
        pic: 'https://res.cloudinary.com/nascvi/image/upload/c_thumb,w_200,g_face/v1588691793/WhatsApp_Image_2020-05-04_at_14.41.40_wswuxq.jpg',
        desc: 'Pontocombo photo miniature',
        month: 'En cours',
        location: 'Professionnel'
    }, 
    {
        pic: 'https://res.cloudinary.com/nascvi/image/upload/v1588691793/WhatsApp_Image_2020-05-04_at_14.41.59_jlruds.jpg',
        desc: 'Emerainville photo miniature',
        month: 'En cours',
        location: 'Maison Individuelle'
    },
    {
        pic: 'https://res.cloudinary.com/nascvi/image/upload/c_thumb,w_200,g_face/v1588691793/WhatsAppImage_znhnrt.jpg',
        desc: 'Champigny sur Marne photo miniature',
        month: 'En cours',
        location: 'Professionnel'
    },
    {
        pic: 'https://res.cloudinary.com/nascvi/image/upload/c_thumb,w_200,g_face/v1588691792/WhatsApp_Image_2020-05-04_at_14.41.28_o7mi1h.jpg',
        desc: 'Thiais photo miniature',
        month: 'En cours',
        location: 'Maison individuelle'
    },
    {
        pic: 'https://res.cloudinary.com/nascvi/image/upload/c_thumb,w_200,g_face/v1588691793/WhatsApp_Image_2020-05-04_at_14.42.48_kjzpfk.jpg',
        desc: 'Les Yrris photo miniature',
        month: 'En cours',
        location: 'Professionnel'
    },
    {
        pic: 'https://res.cloudinary.com/nascvi/image/upload/c_thumb,w_200,g_face/v1588691939/IMG_6271_df4p0u.jpg',
        desc: 'Joinville photo miniature',
        month: 'Bien',
        location: 'Maison individuelle'
    },
    {
        pic: 'https://res.cloudinary.com/nascvi/image/upload/c_thumb,w_200,g_face/v1588691938/IMG_7045_eyskje.jpg',
        desc: 'Villiers photo miniature',
        month: 'En cours',
        location: 'Maison individuelle'
    },
    {
        pic: 'https://res.cloudinary.com/nascvi/image/upload/c_thumb,w_200,g_face/v1588691941/IMG_7043_isioae.jpg',
        desc: 'Saint Maurice photo miniature',
        month: 'En cours',
        location: 'Professionnel'
    },
    {
        pic: 'https://res.cloudinary.com/nascvi/image/upload/c_thumb,w_200,g_face/v1623320319/21-lg_bxdom9.jpg',
        desc: 'Roissy en Brie photo miniature',
        month: 'En cours',
        location: 'Professionnel'
    },
    {
        pic: 'https://res.cloudinary.com/nascvi/image/upload/c_thumb,w_200,g_face/v1623320319/20-lg_wcpxs5.jpg',
        desc: 'Saint maur des fossées photo miniature',
        month: 'Bien',
        location: 'Professionnel'
    },
    {
        pic: 'https://res.cloudinary.com/nascvi/image/upload/c_thumb,w_200,g_face/v1623320318/12-lg_hhxfps.jpg',
        desc: 'Bry sur Marne photo miniature',
        month: 'Bien',
        location: 'Professionnel'
    },
    {
        pic: 'https://res.cloudinary.com/nascvi/image/upload/c_thumb,w_200,g_face/v1623320317/6-lg_ke6kus.jpg',
        desc: 'Vincennes photo miniature',
        month: 'Bien',
        location: 'Professionnel'
    },
    {
        pic: 'https://res.cloudinary.com/nascvi/image/upload/c_thumb,w_200,g_face/v1623320316/26-lg_fzlvhv.jpg',
        desc: 'Nogent photo miniature',
        month: 'Bien',
        location: 'Maison individuelle'
    },
    {
        pic: 'https://res.cloudinary.com/nascvi/image/upload/c_thumb,w_200,g_face/v1623320317/1-lg_b7od04.jpg',
        desc: 'Boissy photo miniature',
        month: 'Bien',
        location: 'Professionnel'
    },
]